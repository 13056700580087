import { createApp, h } from 'vue';
import { reactive } from 'vue';
import store from './store'
import Vuex from 'vuex'
import axios from 'axios'
import AppWrapper from './AppWrapper.vue';
import router from './router';
import PrimeVue from 'primevue/config';
import AutoComplete from 'primevue/autocomplete';
// import Accordion from 'primevue/accordion';
// import AccordionTab from 'primevue/accordiontab';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import Badge from 'primevue/badge';
import BadgeDirective from "primevue/badgedirective";
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
// import CascadeSelect from 'primevue/cascadeselect';
// import Carousel from 'primevue/carousel';
import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Chips from 'primevue/chips';
// import ColorPicker from 'primevue/colorpicker';
import Column from 'primevue/column';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmationService from 'primevue/confirmationservice';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
// import FullCalendar from 'primevue/fullcalendar';
// import Galleria from 'primevue/galleria';
// import Image from 'primevue/image';
import InlineMessage from 'primevue/inlinemessage';
import Inplace from 'primevue/inplace';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import Knob from 'primevue/knob';
import Listbox from 'primevue/listbox';
// import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OrderList from 'primevue/orderlist';
// import OrganizationChart from 'primevue/organizationchart';
import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import PanelMenu from 'primevue/panelmenu';
import Password from 'primevue/password';
// import PickList from 'primevue/picklist';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import Rating from 'primevue/rating';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import SelectButton from 'primevue/selectbutton';
import ScrollPanel from 'primevue/scrollpanel';
import ScrollTop from 'primevue/scrolltop';
import Skeleton from 'primevue/skeleton';
import Slider from 'primevue/slider';
import Sidebar from 'primevue/sidebar';
// import SpeedDial from 'primevue/speeddial';
// import SplitButton from 'primevue/splitbutton';
// import Splitter from 'primevue/splitter';
// import SplitterPanel from 'primevue/splitterpanel';
// import Steps from 'primevue/steps';
import StyleClass from 'primevue/styleclass';
import TabMenu from 'primevue/tabmenu';
// import TieredMenu from 'primevue/tieredmenu';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Toolbar from 'primevue/toolbar';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tag from 'primevue/tag';
import Timeline from 'primevue/timeline';
import ToggleButton from 'primevue/togglebutton';
import Tooltip from 'primevue/tooltip';
import Tree from 'primevue/tree';
import TreeSelect from 'primevue/treeselect';
import TreeTable from 'primevue/treetable';
// import TriStateCheckbox from 'primevue/tristatecheckbox';
import VirtualScroller from 'primevue/virtualscroller';

import CodeHighlight from './AppCodeHighlight';
import BlockViewer from './BlockViewer';

// ENAT
import loader from './components/loader/loader.vue'
import loaderSkeleton from './components/loader/loader_skeleton.vue'
import Draggable from 'vuedraggable';
// import VueGridLayout from "vue-grid-layout";
// import GridLayout from 'vue3-drr-grid-layout';
import VueResponsiveGridLayout from 'vue-ts-responsive-grid-layout';

// ENAT styles
import buttonEnat from './components/fragments/buttonEnat.vue';
import textboxEnat from './components/fragments/textboxEnat.vue';
import valueboxEnat from './components/fragments/valueboxEnat.vue';
import widgetHeader from './components/fragments/widgetHeader.vue';

// Dashboard components
// import dashboardPanelValues from './components/dashboard_panelValues.vue'
// import dashboardValue from './components/dashboard_value.vue'
// import dashboardValveBox from './components/dashboard_valveBox.vue'
// import dashboardWeatherBox from './components/dashboard/weatherBox.vue'
// import dashboardValueBox from './components/dashboard/valueBox.vue'
// Widget components
// import widgetEnergyManager from './components/widgets/energyManager.vue'
// import widgetGeoDateTime from './components/widgets/geoDateTime.vue'
// import widgetWeatherStation from './components/widgets/weatherStation.vue'
// import widgetVentilationController from './components/widgets/ventilationController.vue'
// import widgetHeatingController from './components/widgets/heatingController.vue'
// import widgetHeatingBuildingController from './components/widgets/heatingBuildingController.vue'
// import widgetHeatingGhController from './components/widgets/heatingGhController.vue'
// import widgetHeatingTransportController from './components/widgets/heatingTransportController.vue'
// import widgetHeatingValve from './components/widgets/heatingValve.vue'
// import widgetHeatingFwController from './components/widgets/heatingFwController.vue'
// import widgetHeatingCentralController from './components/widgets/heatingCentralController.vue'
// import widgetScreenController from './components/widgets/screenController.vue'
// import widgetGhTempController from './components/widgets/ghTempController.vue'
// import widgetClimateBox from './components/widgets/climateBox.vue'
// import widgetGmiIrrigationUnit from './components/widgets/gmiIrrigationUnit.vue'
// import widgetIrrigationController from './components/widgets/irrigationController.vue'
// import widgetFertiController from './components/widgets/fertiController.vue'
// import widgetIrrigationGroup from './components/widgets/irrigationGroup.vue'
// import widgetIrrigationValve from './components/widgets/irrigationValve.vue'
// import widgetSprayingController from './components/widgets/sprayingController.vue'
// import widgetIlluminationController from './components/widgets/illuminationController.vue'
// import widgetIlluminationControllerDali from './components/widgets/illuminationControllerDali.vue'
// import widgetIlluminationControllerDaliDim from './components/widgets/illuminationControllerDaliDim.vue'
// import widgetIlluminationPhotonMixer from './components/widgets/illuminationPhotonMixer.vue'
// import widgetFans from './components/widgets/fans.vue'
// import widgetFreshAirFan from './components/widgets/freshAirFan.vue'
// import widgetCo2Controller from './components/widgets/co2Controller.vue'
// import widgetWeekdayController from './components/widgets/weekdayController.vue'
// import widgetAlarmDigital from './components/widgets/alarmDigital.vue'
// import widgetAlarmController from './components/widgets/alarmController.vue'
// import widgetPanelParameter from './components/widgets/panelParameter.vue'
// import widgetPanelParameterList from './components/widgets/panelParameterList.vue'
// import widgetPanelCharts from './components/widgets/panelCharts.vue'
// import navBarMenu from './components/navBar_Menu.vue'

import dialogIoSingle from './components/dialog/dialog_io_single.vue'
import dialogIoNode from './components/dialog/dialog_io_node.vue'
import dialogIoMulti from './components/dialog/dialog_io_multi.vue'
// import dialogValve from './components/dialog/dialog_valve.vue'
import dialogChart from './components/dialog/dialog_chart.vue'
import dialogParameter from './components/dialog/dialog_parameter.vue'
import dialogParameterMulti from './components/dialog/dialog_parameter_multi.vue'

// Charts
import VueApexCharts from "vue3-apexcharts";

import VueKonva from 'vue-konva';

import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
// import 'prismjs/themes/prism-coy.css';
// import './assets/demo/flags/flags.css';
import './assets/css/metro-colors.min.css';
import './assets/css/metro-icons.min.css';
import './assets/icons/uicons-regular-rounded/css/uicons-regular-rounded.css';

// const lodash = require('lodash');
// global.lodash = lodash;

// localStorage.clear();
store.dispatch('auth/setAuthorizationHeaderFromStorage');

const app = createApp({
    render () { return h(AppWrapper); }
});

store.$app = app;

app.use(PrimeVue, { ripple: true, inputStyle: 'filled' });
app.use(ConfirmationService);
app.use(ToastService);
app.use(store);
app.use(Vuex);
app.use(router);
// app.use(VueGridLayout);
// app.use(GridLayout);
app.use(VueResponsiveGridLayout);
app.use(VueApexCharts);
app.use(VueKonva);

app.config.globalProperties.$appState = reactive({ RTL: false, isNewThemeLoaded: false, layoutMode: 'dark' });

app.directive('tooltip', Tooltip);
app.directive('badge', BadgeDirective);
app.directive('ripple', Ripple);
app.directive('code', CodeHighlight);
app.directive('styleclass', StyleClass);

// app.component('Accordion', Accordion);
// app.component('AccordionTab', AccordionTab);
app.component('AutoComplete', AutoComplete);
app.component('Avatar', Avatar);
app.component('AvatarGroup', AvatarGroup);
app.component('Badge', Badge);
app.component('Breadcrumb', Breadcrumb);
app.component('Button', Button);
app.component('Calendar', Calendar);
app.component('Card', Card);
// app.component('Carousel', Carousel);
// app.component('CascadeSelect', CascadeSelect);
app.component('Chart', Chart);
app.component('Checkbox', Checkbox);
app.component('Chip', Chip);
app.component('Chips', Chips);
// app.component('ColorPicker', ColorPicker);
app.component('Column', Column);
app.component('ConfirmDialog', ConfirmDialog);
app.component('ConfirmPopup', ConfirmPopup);
app.component('ContextMenu', ContextMenu);
app.component('DataTable', DataTable);
app.component('DataView', DataView);
app.component('DataViewLayoutOptions', DataViewLayoutOptions);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('Fieldset', Fieldset);
app.component('FileUpload', FileUpload);
// app.component('FullCalendar', FullCalendar);
// app.component('Galleria', Galleria);
// app.component('Image', Image);
app.component('InlineMessage', InlineMessage);
app.component('Inplace', Inplace);
app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('Knob', Knob);
app.component('Listbox', Listbox);
// app.component('MegaMenu', MegaMenu);
app.component('Menu', Menu);
app.component('Menubar', Menubar);
app.component('Message', Message);
app.component('MultiSelect', MultiSelect);
app.component('OrderList', OrderList);
// app.component('OrganizationChart', OrganizationChart);
app.component('OverlayPanel', OverlayPanel);
app.component('Paginator', Paginator);
app.component('Panel', Panel);
app.component('PanelMenu', PanelMenu);
app.component('Password', Password);
// app.component('PickList', PickList);
app.component('ProgressBar', ProgressBar);
app.component('ProgressSpinner', ProgressSpinner);
app.component('RadioButton', RadioButton);
app.component('Rating', Rating);
app.component('SelectButton', SelectButton);
app.component('ScrollPanel', ScrollPanel);
app.component('ScrollTop', ScrollTop);
app.component('Slider', Slider);
app.component('Sidebar', Sidebar);
app.component('Skeleton', Skeleton);
// app.component('SpeedDial', SpeedDial);
// app.component('SplitButton', SplitButton);
// app.component('Splitter', Splitter);
// app.component('SplitterPanel', SplitterPanel);
// app.component('Steps', Steps);
app.component('TabMenu', TabMenu);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Tag', Tag);
app.component('Textarea', Textarea);
// app.component('TieredMenu', TieredMenu);
app.component('Timeline', Timeline);
app.component('Toast', Toast);
app.component('Toolbar', Toolbar);
app.component('ToggleButton', ToggleButton);
app.component('Tree', Tree);
app.component('TreeSelect', TreeSelect);
app.component('TreeTable', TreeTable);
// app.component('TriStateCheckbox', TriStateCheckbox);
app.component('VirtualScroller', VirtualScroller);

app.component('BlockViewer', BlockViewer);

app.component('Draggable', Draggable);
app.component('VueApexCharts', VueApexCharts);
app.component('Loader', loader);
app.component('loaderSkeleton', loaderSkeleton);

app.component('buttonEnat', buttonEnat);
app.component('textboxEnat', textboxEnat);
app.component('valueboxEnat', valueboxEnat);
app.component('widgetHeader', widgetHeader);

// app.component('dashboardPanelValues', dashboardPanelValues);
// app.component('dashboardValue', dashboardValue);
// app.component('dashboardValueBox', dashboardValueBox);
// app.component('dashboardWeatherBox', dashboardWeatherBox);
// app.component('dashboardValveBox', dashboardValveBox);
// app.component('widgetVentilationController', widgetVentilationController);
// app.component('widgetHeatingController', widgetHeatingController);
// app.component('widgetHeatingBuildingController', widgetHeatingBuildingController);
// app.component('widgetHeatingGhController', widgetHeatingGhController);
// app.component('widgetHeatingTransportController', widgetHeatingTransportController);
// app.component('widgetHeatingValve', widgetHeatingValve);
// app.component('widgetHeatingFwController', widgetHeatingFwController);
// app.component('widgetHeatingCentralController', widgetHeatingCentralController);
// app.component('widgetScreenController', widgetScreenController);
// app.component('widgetGeoDateTime', widgetGeoDateTime);
// app.component('widgetWeatherStation', widgetWeatherStation);
// app.component('widgetGhTempController', widgetGhTempController);
// app.component('widgetClimateBox', widgetClimateBox);
// app.component('widgetGmiIrrigationUnit',widgetGmiIrrigationUnit);
// app.component('widgetIrrigationController',widgetIrrigationController);
// app.component('widgetFertiController',widgetFertiController);
// app.component('widgetIrrigationGroup',widgetIrrigationGroup);
// app.component('widgetIrrigationValve',widgetIrrigationValve);
// app.component('widgetSprayingController', widgetSprayingController);
// app.component('widgetIlluminationController', widgetIlluminationController);
// app.component('widgetIlluminationControllerDali', widgetIlluminationControllerDali);
// app.component('widgetIlluminationControllerDaliDim', widgetIlluminationControllerDaliDim);
// app.component('widgetIlluminationPhotonMixer', widgetIlluminationPhotonMixer);
// app.component('widgetFans', widgetFans);
// app.component('widgetFreshAirFan', widgetFreshAirFan);
// app.component('widgetCo2Controller', widgetCo2Controller);
// app.component('widgetWeekdayController', widgetWeekdayController);
// app.component('widgetEnergyManager', widgetEnergyManager);
// app.component('widgetAlarmDigital', widgetAlarmDigital);
// app.component('widgetAlarmController', widgetAlarmController);
// app.component('widgetPanelParameter', widgetPanelParameter);
// app.component('widgetPanelParameterList', widgetPanelParameterList);
// app.component('widgetPanelCharts', widgetPanelCharts);

// app.component('navBarMenu', navBarMenu);
app.component('dialogIoSingle', dialogIoSingle);
app.component('dialogIoNode', dialogIoNode);
app.component('dialogIoMulti', dialogIoMulti);
// app.component('dialogValve', dialogValve);
app.component('dialogChart', dialogChart);
app.component('dialogParameter', dialogParameter);
app.component('dialogParameterMulti', dialogParameterMulti);

app.provide('axios', axios)

app.mount('#app');